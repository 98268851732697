import { NotificationType } from '@naturehouse/design-system/components/atoms/notification/Notification';
import UnavailableMethodException from '@naturehouse/nh-essentials/lib/exceptions/UnavailableMethodException';
import type HttpError from '@naturehouse/nh-essentials/lib/requests/HttpError';
import CollectionBase from '../../common/CollectionBase';
import createNotification from '../../util/notification';

type PriceListHouseInformation = {
    houseId: number;
    mainImageUrl: string;
    country: string;
    region: string;
};

type PriceListBookingInformation = {
    arrivalDate: string;
    departureDate: string;
    totalNumberOfPersons: number;
    numberOfAdults: number;
    numberOfChildren: number;
    numberOfBabies: number;
    numberOfPets: number;
};

type PriceListPriceInformationDeposit = {
    value: number | null;
    type: string | null;
};

export type PriceListExtraCost = {
    id: number;
    name: string;
    optional: boolean;
    paymentType: string;
    price: number;
    priceType: string;
    selected: boolean;
    totalPrice: number | null;
};

type PriceListTotal = {
    base: number;
    deposit: number;
    discount: number;
    total: number;
};

type PriceListInsurance = {
    value: number;
    enabled: boolean;
};

type PriceListPriceInformation = {
    basePrice: number;
    includedCosts: string[];
    bringYourOwnItems: string[];
    extraCosts: PriceListExtraCost[];
    serviceFee: number;
    insurance: PriceListInsurance;
    deposit: PriceListPriceInformationDeposit;
    discount: number | null;
    total: PriceListTotal;
};

export type PriceListCollectionApiData = {
    houseInformation: PriceListHouseInformation;
    bookingInformation: PriceListBookingInformation;
    priceInformation: PriceListPriceInformation;
};

export type PriceListCollectionEndpointParams = {
    houseId: string;
    searchParams: URLSearchParams;
};

export default class PriceListCollection extends CollectionBase<
    PriceListCollectionApiData,
    PriceListCollectionEndpointParams
> {
    static #instance: PriceListCollection | null = null;

    public static getInstance(): PriceListCollection {
        if (this.#instance === null) {
            this.#instance = new PriceListCollection();
        }

        return this.#instance;
    }

    public static destroyInstance(): void {
        this.#instance = null;
    }

    private constructor() {
        super();
    }

    public async retrieve<RT>(params: PriceListCollectionEndpointParams): Promise<RT | void> {
        return super.retrieve(params);
    }

    public async add(): Promise<void> {
        throw new UnavailableMethodException();
    }

    public async delete(): Promise<void> {
        throw new UnavailableMethodException();
    }

    public async update(): Promise<void> {
        throw new UnavailableMethodException();
    }

    protected endpoint(params: PriceListCollectionEndpointParams): string {
        if (!params.houseId || !params.searchParams) {
            throw new Error('Incorrect parameters');
        }

        return `/api/houses/${params.houseId}/price-list?${params.searchParams}`;
    }

    protected onError(error: HttpError<AjaxResponseError>): void {
        if (!error.body) {
            return;
        }

        createNotification(NotificationType.ERROR, error.body.message, 0);
    }
}
