var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/protons/icon/Icon.macro.njk"] = require( "@naturehouse/design-system/components/protons/icon/Icon.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/molecules/pagination/Pagination.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("@naturehouse/design-system/components/protons/icon/Icon.macro.njk", false, "design-system/v3/components/molecules/pagination/Pagination.html.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("iconTemplate", t_1);
output += "\n\n";
var t_4;
t_4 = (lineno = 2, colno = 42, runtime.callWrap(runtime.memberLookup((t_1),"render"), "iconTemplate[\"render\"]", context, [{"name": "arrow-left","size": 1.25}]));
frame.set("previousLabel", t_4, true);
if(frame.topLevel) {
context.setVariable("previousLabel", t_4);
}
if(frame.topLevel) {
context.addExport("previousLabel", t_4);
}
output += "\n\n";
var t_5;
t_5 = (lineno = 7, colno = 38, runtime.callWrap(runtime.memberLookup((t_1),"render"), "iconTemplate[\"render\"]", context, [{"name": "arrow-right","size": 1.25}]));
frame.set("nextLabel", t_5, true);
if(frame.topLevel) {
context.setVariable("nextLabel", t_5);
}
if(frame.topLevel) {
context.addExport("nextLabel", t_5);
}
output += "\n\n";
var t_6;
t_6 = ["nh-pagination-new__item"];
frame.set("itemClasses", t_6, true);
if(frame.topLevel) {
context.setVariable("itemClasses", t_6);
}
if(frame.topLevel) {
context.addExport("itemClasses", t_6);
}
output += "\n\n<nav class=\"nh-pagination-new\"";
frame = frame.push();
var t_9 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "data"));
if(t_9) {t_9 = runtime.fromIterator(t_9);
var t_7;
if(runtime.isArray(t_9)) {
var t_8 = t_9.length;
for(t_7=0; t_7 < t_9.length; t_7++) {
var t_10 = t_9[t_7][0];
frame.set("[object Object]", t_9[t_7][0]);
var t_11 = t_9[t_7][1];
frame.set("[object Object]", t_9[t_7][1]);
frame.set("loop.index", t_7 + 1);
frame.set("loop.index0", t_7);
frame.set("loop.revindex", t_8 - t_7);
frame.set("loop.revindex0", t_8 - t_7 - 1);
frame.set("loop.first", t_7 === 0);
frame.set("loop.last", t_7 === t_8 - 1);
frame.set("loop.length", t_8);
output += " ";
output += runtime.suppressValue(t_10, env.opts.autoescape);
if(t_11) {
output += "=\"";
output += runtime.suppressValue(t_11, env.opts.autoescape);
output += "\" ";
;
}
;
}
} else {
t_7 = -1;
var t_8 = runtime.keys(t_9).length;
for(var t_12 in t_9) {
t_7++;
var t_13 = t_9[t_12];
frame.set("name", t_12);
frame.set("value", t_13);
frame.set("loop.index", t_7 + 1);
frame.set("loop.index0", t_7);
frame.set("loop.revindex", t_8 - t_7);
frame.set("loop.revindex0", t_8 - t_7 - 1);
frame.set("loop.first", t_7 === 0);
frame.set("loop.last", t_7 === t_8 - 1);
frame.set("loop.length", t_8);
output += " ";
output += runtime.suppressValue(t_12, env.opts.autoescape);
if(t_13) {
output += "=\"";
output += runtime.suppressValue(t_13, env.opts.autoescape);
output += "\" ";
;
}
;
}
}
}
frame = frame.pop();
output += ">\n    <ol class=\"nh-pagination-new__list\">\n        <li class=\"nh-pagination-new__prev\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "previous")),"label"), env.opts.autoescape);
output += "\">\n            ";
if(runtime.contextOrFrameLookup(context, frame, "current") != 1) {
output += "\n                <a class=\"nh-pagination-new__item nh-pagination-new__item--active\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "previous")),"href"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "previousLabel"), env.opts.autoescape);
output += "</a>\n            ";
;
}
else {
output += "\n                <span class=\"nh-pagination-new__item nh-pagination-new__item--inactive\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "previousLabel"), env.opts.autoescape);
output += "</span>\n            ";
;
}
output += "\n        </li>\n        <li class=\"nh-pagination-new__label\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "current"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "labels")),"of"),"of"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "total"), env.opts.autoescape);
output += "</li>\n\n        ";
if(runtime.contextOrFrameLookup(context, frame, "current") != 1 && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "first")),"href") != runtime.memberLookup(((env.getFilter("first").call(context, runtime.contextOrFrameLookup(context, frame, "pages")))),"href")) {
output += "\n            <li class=\"nh-pagination-new__page\">\n                <a class=\"";
output += runtime.suppressValue(env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "itemClasses")," "), env.opts.autoescape);
output += "\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "first")),"href"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "first")),"label"), env.opts.autoescape);
output += "</a>\n            </li>\n        ";
;
}
output += "\n\n        ";
if(runtime.contextOrFrameLookup(context, frame, "current") > 4 && runtime.contextOrFrameLookup(context, frame, "total") > 6) {
output += "\n            <li class=\"nh-pagination-new__page\">\n                <span class=\"";
output += runtime.suppressValue(env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "itemClasses")," "), env.opts.autoescape);
output += " nh-pagination-new__item--hidden\">...</span>\n            </li>\n        ";
;
}
output += "\n\n        ";
frame = frame.push();
var t_16 = runtime.contextOrFrameLookup(context, frame, "pages");
if(t_16) {t_16 = runtime.fromIterator(t_16);
var t_15 = t_16.length;
for(var t_14=0; t_14 < t_16.length; t_14++) {
var t_17 = t_16[t_14];
frame.set("page", t_17);
frame.set("loop.index", t_14 + 1);
frame.set("loop.index0", t_14);
frame.set("loop.revindex", t_15 - t_14);
frame.set("loop.revindex0", t_15 - t_14 - 1);
frame.set("loop.first", t_14 === 0);
frame.set("loop.last", t_14 === t_15 - 1);
frame.set("loop.length", t_15);
output += "\n            ";
if(runtime.contextOrFrameLookup(context, frame, "current") == runtime.memberLookup((t_17),"label")) {
output += "\n                <li class=\"nh-pagination-new__page\">\n                    <span class=\"";
output += runtime.suppressValue(env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "itemClasses")," "), env.opts.autoescape);
output += " nh-pagination-new__item--selected\">";
output += runtime.suppressValue(runtime.memberLookup((t_17),"label"), env.opts.autoescape);
output += "</span>\n                </li>\n            ";
;
}
else {
output += "\n                <li class=\"nh-pagination-new__page\">\n                    <a class=\"";
output += runtime.suppressValue(env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "itemClasses")," "), env.opts.autoescape);
output += "\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_17),"href"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_17),"label"), env.opts.autoescape);
output += "</a>\n                </li>\n            ";
;
}
output += "\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
if(runtime.contextOrFrameLookup(context, frame, "current") <= runtime.contextOrFrameLookup(context, frame, "total") - 4 && runtime.contextOrFrameLookup(context, frame, "total") > 6) {
output += "\n            <li class=\"nh-pagination-new__page\">\n                <span class=\"";
output += runtime.suppressValue(env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "itemClasses")," "), env.opts.autoescape);
output += " nh-pagination-new__item--hidden\">...</span>\n            </li>\n        ";
;
}
output += "\n\n        ";
if(runtime.contextOrFrameLookup(context, frame, "current") != runtime.contextOrFrameLookup(context, frame, "total") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "last")),"href") != runtime.memberLookup(((env.getFilter("last").call(context, runtime.contextOrFrameLookup(context, frame, "pages")))),"href")) {
output += "\n            <li class=\"nh-pagination-new__page\">\n                <a class=\"";
output += runtime.suppressValue(env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "itemClasses")," "), env.opts.autoescape);
output += "\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "last")),"href"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "last")),"label"), env.opts.autoescape);
output += "</a>\n            </li>\n        ";
;
}
output += "\n\n        <li class=\"nh-pagination-new__next\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "next")),"label"), env.opts.autoescape);
output += "\">\n            ";
if(runtime.contextOrFrameLookup(context, frame, "current") != runtime.contextOrFrameLookup(context, frame, "total")) {
output += "\n                <a class=\"nh-pagination-new__item nh-pagination-new__item--active\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "next")),"href"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "nextLabel"), env.opts.autoescape);
output += "</a>\n            ";
;
}
else {
output += "\n                <span class=\"nh-pagination-new__item nh-pagination-new__item--inactive\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "nextLabel"), env.opts.autoescape);
output += "</span>\n            ";
;
}
output += "\n        </li>\n    </ol>\n</nav>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/molecules/pagination/Pagination.html.njk"] , dependencies)