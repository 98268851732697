enum HouseDetailTrackingEvent {
    OPEN_GALLERY = 'detail_open_gallery',
    NEXT_IMAGE_CLICK = 'detail_next_image_click',
    PREV_IMAGE_CLICK = 'detail_prev_image_click',
    NEXT_IMAGE_SWIPE = 'detail_next_image_swipe',
    PREV_IMAGE_SWIPE = 'detail_prev_image_swipe',
    IMAGE_CLICK = 'detail_image_click',
    IMAGE_SCROLL_INTO_VIEW = 'detail_image_scroll_into_view',
    THUMBNAIL_CLICK = 'detail_thumbnail_click',
    GALLERY_NEXT_IMAGE_CLICK = 'detail_gallery_next_image_click',
    GALLERY_PREV_IMAGE_CLICK = 'detail_gallery_prev_image_click',
    GALLERY_NEXT_IMAGE_SWIPE = 'detail_gallery_next_image_swipe',
    GALLERY_PREV_IMAGE_SWIPE = 'detail_gallery_prev_image_swipe',
    REVIEW_BUTTON_CLICK = 'detail_review_button_click',
    DESCRIPTION_READ_MORE_CLICK = 'detail_description_read_more_click',
    CONTACT_WITH_LANDLORD_BUTTON_CLICK = 'detail_contact_landlord_button_click',
    OPEN_FACILITIES_BUTTON_CLICK = 'detail_open_facilities_button_click',
    SHOW_LOCATION_BUTTON_CLICK = 'detail_show_location_button_click',
    DESCRIPTION_INTERSECTION_EVENT = 'detail_description_intersection_event',
    FACILITIES_INTERSECTION_EVENT = 'detail_facilities_intersection_event',
    MAP_INTERSECTION_EVENT = 'detail_map_intersection_event',
    GOOD_TO_KNOW_INTERSECTION_EVENT = 'detail_good_to_know_intersection_event',
    REVIEWS_INTERSECTION_EVENT = 'detail_reviews_intersection_event',
    ARRIVAL_DATE_SELECTION = 'detail_arrival_date_selection',
    DEPARTURE_DATE_SELECTION = 'detail_departure_date_selection',
    TRAVEL_PARTY_SELECTION = 'detail_travel_party_selection',
    VIEW_ALL_PHOTOS = 'detail_view_all_photos',
    OPEN_SUSTAINABILITY_BUTTON_CLICK = 'detail_open_sustainability_button_click',
    BACK_BUTTON_CLICK = 'detail_click_back_button'
}

export default HouseDetailTrackingEvent;
