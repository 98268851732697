import GoogleAnalyticsEvents from '../../../common/GoogleAnalyticsEvents';

export default class DetailFormTracking {
    readonly #formElement: HTMLFormElement;

    constructor(form: HTMLFormElement) {
        this.#formElement = form;
    }

    public trackDetailPageImpressionSqueezely(
        companionData: TravelPartyDataInterface,
        dateSelection: DateRangeString,
        destination: string
    ): void {
        const dataLayerElement = document.getElementById('data-layer');

        if (!dataLayerElement) {
            return;
        }

        let initialData = {};
        let price: number | string = '';
        const priceElements: HTMLElement[] = Array.from(
            this.#formElement.querySelectorAll('[data-total-price]')
        );
        const priceElement = priceElements.find(
            (el: HTMLElement): boolean => el.style.display !== 'none'
        );

        if (dataLayerElement.hasAttribute('content')) {
            initialData = JSON.parse(dataLayerElement.getAttribute('content') ?? '');
        }

        if (priceElement) {
            price = Number(priceElement.dataset.totalPrice) || '';
        }

        const data: GoogleAnalytics4ViewContentEvent = {
            ...initialData,
            custom_adults: companionData.adults,
            custom_children: companionData.children,
            custom_babies: companionData.babies,
            custom_pets: companionData.pets,
            custom_location: destination,
            custom_totalprice: price,
            checkin_date: dateSelection.start ?? '',
            checkout_date: dateSelection.end ?? ''
        };

        if (data.products && data.products[0]?.id) {
            data.products[0].item_id = data.products[0].id;
        }

        GoogleAnalyticsEvents.trackEvent<GoogleAnalytics4ViewContentEvent>('ViewContent', data);
    }

    public trackDetailPageImpression(): void {
        let houseData: Record<string, string | object | []> = {};
        const dataLayerElement = document.getElementById('data-layer');

        if (dataLayerElement && dataLayerElement.hasAttribute('content')) {
            const dataLayerContent = dataLayerElement.getAttribute('content');
            if (!dataLayerContent) {
                return;
            }

            houseData = JSON.parse(dataLayerContent);
        }

        if (!houseData || Object.keys(houseData).length === 0) {
            return;
        }

        let price: number | null = null;
        const priceElement: HTMLElement | null =
            this.#formElement.querySelector('[data-total-price]');
        if (priceElement) {
            price = Number(priceElement.dataset.totalPrice) ?? null;
        }

        if (!Array.isArray(houseData.products)) {
            return;
        }

        const house: dataLayerEcommerceProduct = houseData.products[0];
        GoogleAnalyticsEvents.productDetailImpression(house, price);
    }

    public processAddToCartData(
        dataLayer: Record<string, any>,
        companionData: TravelPartyDataInterface,
        quantity: number,
        dateSelection: DateRangeString
    ): void {
        if (!dateSelection.start || !dateSelection.end) {
            return;
        }

        const priceElement: HTMLElement | null =
            this.#formElement.querySelector('[data-total-price]');
        let price = 0;
        let pricePerNight = '';

        if (priceElement) {
            price = Number(priceElement.dataset.totalPrice) || 0;
            pricePerNight = Number(price / quantity).toFixed(2);
        }

        GoogleAnalyticsEvents.addToCart(dataLayer, price, pricePerNight);
    }
}
