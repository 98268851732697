import '../../atoms/button/Button';
import '../../atoms/image/Image';
/* eslint-disable import/no-duplicates */
import '../../atoms/carousel-indicators/CarouselIndicators';
import CarouselIndicators, {
    CarouselIndicatorsEvents
} from '../../atoms/carousel-indicators/CarouselIndicators';
import '../../molecules/slides-carousel/SlidesCarousel';
import SlidesCarousel, {
    SlidesCarouselEvent
} from '../../molecules/slides-carousel/SlidesCarousel';
/* eslint-enable import/no-duplicates */
import type { DefaultComponentType } from '../../../../src/utils/types';
import type { ImageProps } from '../../atoms/image/Image';
import './ImageCarousel.pcss';

export enum ImageCarouselEvent {
    NEXT_BUTTON_CLICK = 'image-carousel-next-button-click',
    PREV_BUTTON_CLICK = 'image-carousel-prev-button-click',
    NEXT_SWIPE_ACTION = 'image-carousel-next-swipe-action',
    PREV_SWIPE_ACTION = 'image-carousel-prev-swipe-action',
    THUMBNAIL_CLICK = 'image-carousel-thumbnail-click',
    SLIDE_CHANGED = 'image-carousel-slide-changed'
}

export enum CarouselSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large'
}

export type ImageCarouselProps = DefaultComponentType & {
    slides: ImageProps[];
    size: CarouselSize;
    thumbnails?: ImageProps[];
    loadNextImage?: boolean;
    leftBottomSlot?: string;
};

export default class ImageCarousel extends HTMLElement {
    #current = 0;

    #touchStartX = 0;

    get current(): number {
        return this.#current;
    }

    #slidesCarousel: SlidesCarousel | null = null;

    #thumbnailCarousel: SlidesCarousel | null = null;

    #carouselIndicators: CarouselIndicators | null = null;

    #prevButtons: HTMLButtonElement[] = [];

    #nextButtons: HTMLButtonElement[] = [];

    #thumbnailCarouselSlides: HTMLElement[] | null = null;

    protected connectedCallback(): void {
        this.#connectElements();

        this.#slidesCarousel?.addEventListener('changed', this.#slidesCarouselChangedEventHandler);
        this.#slidesCarousel?.addEventListener('touchstart', this.#handleTouchStart, {
            passive: true
        });
        this.#slidesCarousel?.addEventListener('touchend', this.#handleTouchEnd);
        this.#slidesCarousel?.addEventListener(
            SlidesCarouselEvent.SLIDE_CHANGED,
            this.#slidesCarouselSlideChangedEventHandler
        );

        this.#prevButtons.forEach((button) => {
            button.addEventListener('click', this.#prevClickHandler);
        });

        this.#nextButtons.forEach((button) => {
            button.addEventListener('click', this.#nextClickHandler);
        });

        this.#thumbnailCarouselSlides?.forEach((slide, index) => {
            slide.addEventListener('click', (event) => this.#handleThumbnailClick(event, index));
        });

        this.addEventListener(CarouselIndicatorsEvents.SELECTED_INDICATOR, (event) =>
            this.changeHandler(event, (event as CustomEvent).detail)
        );
    }

    protected disconnectedCallback(): void {
        this.#slidesCarousel?.removeEventListener(
            'changed',
            this.#slidesCarouselChangedEventHandler
        );
        this.#slidesCarousel?.removeEventListener('touchstart', this.#handleTouchStart);
        this.#slidesCarousel?.removeEventListener('touchend', this.#handleTouchEnd);
        this.#slidesCarousel?.removeEventListener(
            SlidesCarouselEvent.SLIDE_CHANGED,
            this.#slidesCarouselSlideChangedEventHandler
        );

        this.#prevButtons.forEach((button) => {
            button.removeEventListener('click', this.#prevClickHandler);
        });

        this.#nextButtons.forEach((button) => {
            button.removeEventListener('click', this.#nextClickHandler);
        });

        this.#thumbnailCarouselSlides?.forEach((slide, index) => {
            slide.removeEventListener('click', (event) => this.#handleThumbnailClick(event, index));
        });

        this.removeEventListener(CarouselIndicatorsEvents.SELECTED_INDICATOR, (event) =>
            this.changeHandler(event, (event as CustomEvent).detail)
        );
    }

    #imagesChangeHandler = (): void => {
        if (!this.#slidesCarousel) {
            return;
        }

        this.#current = this.#slidesCarousel.slide;

        this.#thumbnailCarouselSlides?.forEach((slide, index) => {
            slide.toggleAttribute('active', index === this.current);

            if (index !== this.current) {
                return;
            }

            this.#slidesCarousel?.scrollToSlide(index);
            this.#thumbnailCarousel?.scrollToSlide(index);
        });

        if (this.#carouselIndicators) {
            this.#carouselIndicators.activeIndex = this.current;
        }
    };

    changeHandler = (event: Event, index: number, animate = true): void => {
        event.preventDefault();
        event.stopPropagation();

        this.#slidesCarousel?.scrollToSlide(index, 0, animate);
        this.#thumbnailCarousel?.scrollToSlide(index, 0, animate);

        if (this.#carouselIndicators) {
            this.#carouselIndicators.activeIndex = index;
        }

        this.#imagesChangeHandler();
    };

    #prevClickHandler = (event: Event): void => {
        event.preventDefault();
        event.stopPropagation();

        this.#slidesCarousel?.prev();
        this.#thumbnailCarousel?.prev();
        if (this.#carouselIndicators) {
            this.#carouselIndicators.activeIndex--;
        }

        this.#imagesChangeHandler();

        this.dispatchEvent(new Event(ImageCarouselEvent.PREV_BUTTON_CLICK));
    };

    #nextClickHandler = (event: Event): void => {
        event.preventDefault();
        event.stopPropagation();

        this.#slidesCarousel?.next();
        this.#thumbnailCarousel?.next();

        if (this.#carouselIndicators) {
            this.#carouselIndicators.activeIndex++;
        }

        this.#imagesChangeHandler();

        this.dispatchEvent(new Event(ImageCarouselEvent.NEXT_BUTTON_CLICK));
    };

    #connectElements(): void {
        this.#slidesCarousel = this.querySelector('nh-slides-carousel[data-role="images"]');

        this.#thumbnailCarousel = this.querySelector('nh-slides-carousel[data-role="thumbnails"]');

        this.#carouselIndicators = this.querySelector('nh-carousel-indicators');

        this.#prevButtons = Array.from(this.querySelectorAll('button[data-role="prev"]'));

        this.#nextButtons = Array.from(this.querySelectorAll('button[data-role="next"]'));

        if (this.#thumbnailCarousel) {
            this.#thumbnailCarouselSlides = Array.from(
                this.#thumbnailCarousel.querySelectorAll('[slot="slide"]')
            );
        }
    }

    #handleTouchStart = (event: TouchEvent): void => {
        this.#touchStartX = event.touches[0].clientX;
    };

    #handleTouchEnd = (event: TouchEvent): void => {
        if (this.#touchStartX === event.changedTouches[0].clientX) {
            return;
        }

        if (this.#touchStartX < event.changedTouches[0].clientX) {
            this.dispatchEvent(new Event(ImageCarouselEvent.PREV_SWIPE_ACTION));
            return;
        }

        this.dispatchEvent(new Event(ImageCarouselEvent.NEXT_SWIPE_ACTION));
    };

    #handleThumbnailClick = (event: Event, index: number) => {
        this.changeHandler(event, index);
        this.dispatchEvent(
            new CustomEvent(ImageCarouselEvent.THUMBNAIL_CLICK, {
                detail: {
                    index
                }
            })
        );
    };

    #slidesCarouselChangedEventHandler = (): void => {
        this.#imagesChangeHandler();

        this.dispatchEvent(
            new CustomEvent(ImageCarouselEvent.SLIDE_CHANGED, {
                detail: {
                    index: this.current
                }
            })
        );
    };

    #slidesCarouselSlideChangedEventHandler = (event: Event): void => {
        if (!(event instanceof CustomEvent)) {
            return;
        }

        this.dispatchEvent(new CustomEvent(ImageCarouselEvent.SLIDE_CHANGED, event));
    };
}

if (!customElements.get('nh-image-carousel')) {
    customElements.define('nh-image-carousel', ImageCarousel);
}
