import convertFormdataToJsonObject from '../../util/objects';
import PriceListModel, { type PriceListApiSearchParams } from './PriceListModel';

type DetailPriceListFormDataType = {
    'price_calculation[dateRange][arrivalDate]': string;
    'price_calculation[dateRange][departureDate]': string;
    'price_calculation[adultAmount]': number;
    'price_calculation[childrenAmount]': number;
    'price_calculation[babyAmount]': number;
    'price_calculation[petAmount]': number;
};

export default class DetailPriceListModel implements PriceListModel {
    public static parseToSearchParams(formData: FormData): URLSearchParams {
        const data = convertFormdataToJsonObject(formData) as DetailPriceListFormDataType;

        const params: PriceListApiSearchParams = {
            arrivalDate: data['price_calculation[dateRange][arrivalDate]'] ?? '',
            departureDate: data['price_calculation[dateRange][departureDate]'] ?? '',
            numberOfAdults: (data['price_calculation[adultAmount]'] ?? 0).toString(),
            numberOfChildren: (data['price_calculation[childrenAmount]'] ?? 0).toString(),
            numberOfBabies: (data['price_calculation[babyAmount]'] ?? 0).toString(),
            numberOfPets: (data['price_calculation[petAmount]'] ?? 0).toString(),
            insurance: '0'
        };

        return new URLSearchParams(params);
    }
}
