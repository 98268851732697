var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/atoms/tool-tip/ToolTip.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<nh-tool-tip\n    target-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "id"), env.opts.autoescape);
output += "\"\n    placement=\"";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "placement"),"bottom-start"), env.opts.autoescape);
output += "\"";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "endpoint"),false)) {
output += " endpoint=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "endpoint"), env.opts.autoescape);
output += "\"";
;
}
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "show")) === true && runtime.contextOrFrameLookup(context, frame, "show") == true) {
output += " show";
;
}
frame = frame.push();
var t_3 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "data"));
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_1;
if(runtime.isArray(t_3)) {
var t_2 = t_3.length;
for(t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1][0];
frame.set("[object Object]", t_3[t_1][0]);
var t_5 = t_3[t_1][1];
frame.set("[object Object]", t_3[t_1][1]);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += " ";
output += runtime.suppressValue(t_4, env.opts.autoescape);
if(t_5) {
output += "=\"";
output += runtime.suppressValue(t_5, env.opts.autoescape);
output += "\"";
;
}
;
}
} else {
t_1 = -1;
var t_2 = runtime.keys(t_3).length;
for(var t_6 in t_3) {
t_1++;
var t_7 = t_3[t_6];
frame.set("name", t_6);
frame.set("value", t_7);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += " ";
output += runtime.suppressValue(t_6, env.opts.autoescape);
if(t_7) {
output += "=\"";
output += runtime.suppressValue(t_7, env.opts.autoescape);
output += "\"";
;
}
;
}
}
}
frame = frame.pop();
output += "\n>\n    ";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "content"),false)) {
output += "\n        <div slot=\"content\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.contextOrFrameLookup(context, frame, "content")), env.opts.autoescape);
output += "</div>\n    ";
;
}
output += "\n    <div slot=\"arrow\"></div>\n</nh-tool-tip>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/atoms/tool-tip/ToolTip.html.njk"] , dependencies)