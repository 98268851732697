export default function convertFormdataToJsonObject(formData: FormData): StandardObjectInterface {
    const data: StandardObjectInterface = {};

    for (const [key, value] of formData.entries()) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            const oldValue = data[key];
            if (!Array.isArray(data[key])) {
                data[key] = [];
                data[key].push(oldValue);
            }

            data[key].push(value);

            continue;
        }

        data[key] = value;
    }

    return data;
}

export function getValidityReasons(form: HTMLFormElement): Record<string, Record<string, boolean>> {
    const reasons: Record<string, Record<string, boolean>> = {};

    const allFields: FormFieldType[] = Array.from(form.querySelectorAll('input, textarea, select'));

    const fields = allFields.filter((field) => field.willValidate);
    for (const field of fields) {
        if (field.validity.valid) {
            continue;
        }

        reasons[field.name] = convertValidityStateToObject(field.validity);
    }

    return reasons;
}

export function convertValidityStateToObject(state: ValidityState): Record<string, boolean> {
    const reasons: Record<string, boolean> = {};

    for (const key in state) {
        if (state[key as keyof ValidityState]) {
            reasons[key] = true;
        }
    }

    return reasons;
}
