var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/protons/icon/Icon.macro.njk"] = require( "@naturehouse/design-system/components/protons/icon/Icon.macro.njk" );
dependencies["@naturehouse/design-system/components/atoms/tool-tip/ToolTip.html.njk"] = require( "@naturehouse/design-system/components/atoms/tool-tip/ToolTip.html.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/molecules/info-tip/InfoTip.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("@naturehouse/design-system/components/protons/icon/Icon.macro.njk", false, "design-system/v3/components/molecules/info-tip/InfoTip.html.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("iconTemplate", t_1);
output += "\n<div class=\"nh-info-tip\">\n    ";
output += runtime.suppressValue((lineno = 2, colno = 26, runtime.callWrap(runtime.memberLookup((t_1),"render"), "iconTemplate[\"render\"]", context, [{"name": "info","size": 0.75,"data": {"data-tool-tip": runtime.contextOrFrameLookup(context, frame, "id")}}])), env.opts.autoescape);
output += "\n</div>";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("@naturehouse/design-system/components/atoms/tool-tip/ToolTip.html.njk", false, "design-system/v3/components/molecules/info-tip/InfoTip.html.njk", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
callback(null,t_4);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_7,t_6) {
if(t_7) { cb(t_7); return; }
callback(null,t_6);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/molecules/info-tip/InfoTip.html.njk"] , dependencies)