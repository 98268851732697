var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/molecules/info-tip/InfoTip.html.njk"] = require( "@naturehouse/design-system/components/molecules/info-tip/InfoTip.html.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/molecules/info-tip/InfoTip.macro.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["params"], 
[], 
function (l_params, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("params", l_params);
var t_2 = "";t_2 += "\n    ";
var t_3;
t_3 = runtime.memberLookup((l_params),"id");
frame.set("id", t_3, true);
if(frame.topLevel) {
context.setVariable("id", t_3);
}
if(frame.topLevel) {
context.addExport("id", t_3);
}
t_2 += "\n    ";
var t_4;
t_4 = env.getFilter("default").call(context, runtime.memberLookup((l_params),"placement"),"bottom-start");
frame.set("placement", t_4, true);
if(frame.topLevel) {
context.setVariable("placement", t_4);
}
if(frame.topLevel) {
context.addExport("placement", t_4);
}
t_2 += "\n    ";
var t_5;
t_5 = env.getFilter("default").call(context, runtime.memberLookup((l_params),"endpoint"),false);
frame.set("endpoint", t_5, true);
if(frame.topLevel) {
context.setVariable("endpoint", t_5);
}
if(frame.topLevel) {
context.addExport("endpoint", t_5);
}
t_2 += "\n    ";
var t_6;
t_6 = env.getFilter("default").call(context, runtime.memberLookup((l_params),"content"),false);
frame.set("content", t_6, true);
if(frame.topLevel) {
context.setVariable("content", t_6);
}
if(frame.topLevel) {
context.addExport("content", t_6);
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("@naturehouse/design-system/components/molecules/info-tip/InfoTip.html.njk", false, "design-system/v3/components/molecules/info-tip/InfoTip.macro.njk", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
callback(null,t_7);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
callback(null,t_9);});
});
tasks.push(
function(result, callback){
t_2 += result;
callback(null);
});
env.waterfall(tasks, function(){
t_2 += "\n";
});
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("render");
context.setVariable("render", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/molecules/info-tip/InfoTip.macro.njk"] , dependencies)