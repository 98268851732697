function calculatePagination(
    skipParameter: number,
    limitParameter: number,
    total: number,
    linkGeneratorCallback: (skip: number, limit: number) => string,
    shouldNormalizeLimit = true
): Record<string, string | null | number | Map<string, string>> {
    const limit = shouldNormalizeLimit ? normalizeLimit(limitParameter) : limitParameter;
    const last: number = Math.ceil(Math.min(total, 10000) / limit);
    const skip: number = normalizeSkip(skipParameter, last, limit);
    const currentPage: number = Math.ceil(skip / limit) + 1;
    const previousPage: number | null = currentPage > 1 ? currentPage - 1 : null;
    const nextPage: number | null = currentPage < last ? currentPage + 1 : null;
    const pages: number[] = calculatePages(currentPage, last);

    const newPages: Map<string, string> = new Map();

    pages.forEach((key: number) => {
        newPages.set(key.toString(), linkGeneratorCallback(calculateSkip(key, limit), limit));
    });

    return {
        first: linkGeneratorCallback(calculateSkip(1, limit), limit),
        prev:
            previousPage === null
                ? null
                : linkGeneratorCallback(calculateSkip(previousPage, limit), limit),
        current: currentPage,
        total: last,
        next:
            nextPage === null ? null : linkGeneratorCallback(calculateSkip(nextPage, limit), limit),
        last: linkGeneratorCallback(calculateSkip(last, limit), limit),
        pages: newPages
    };
}

function normalizeLimit(limitParameter: number): number {
    const limit: number = Math.ceil(limitParameter / 10) * 10;
    return limit < 10 ? 10 : limit;
}

function normalizeSkip(skipParameter: number, last: number, limit: number): number {
    const skip: number = skipParameter < 0 ? 0 : skipParameter;
    return skip > last * limit ? last * limit : Math.floor(skip / limit) * limit;
}

function calculateSkip(page: number, limit: number): number {
    return limit * (page - 1);
}

function calculatePages(current: number, max: number, spread = 2): number[] {
    let first: number = current - spread;
    let last: number = current + spread;

    const fill = (start: number, end: number): number[] => {
        const newPages: number[] = [];
        for (let i: number = start; i <= end; i++) {
            newPages.push(i);
        }

        return newPages;
    };

    if (first <= 0 && last >= max) {
        return fill(1, max);
    }

    if (first <= 0) {
        last += -first + 1;
        first = 1;
        last = last < max ? last : max;
    }

    if (last >= max) {
        first -= last - max;
        last = max;
        first = first <= 0 ? 1 : first;
    }

    return fill(first, last);
}

export default calculatePagination;
