import { Loader } from '@googlemaps/js-api-loader';

declare interface LatLngInterface {
    latitude: number;
    longitude: number;
}

export function instantiateMapLoader(mapsApiKey: string): Loader {
    return new Loader({
        apiKey: mapsApiKey,
        version: 'weekly',
        libraries: ['places']
    });
}

export default class MapsGeoResolver {
    readonly #loader: Loader;

    public constructor(mapsApiKey: string) {
        this.#loader = instantiateMapLoader(mapsApiKey);
    }

    public resolve(searchString: string): Promise<LatLngInterface> {
        return new Promise((resolve, reject) => {
            this.#loader.loadCallback(() => {
                const geocoder: any = new google.maps.Geocoder();

                if (!geocoder || !geocoder.geocode) {
                    reject(new Error('google is not defined'));
                }

                geocoder.geocode({ address: searchString }, (results: any, status: any): void => {
                    if (status !== google.maps.GeocoderStatus.OK || !results[0]) {
                        resolve({
                            latitude: 0,
                            longitude: 0
                        });
                        return;
                    }

                    const gps: any = results[0].geometry.location;
                    const latLng: LatLngInterface = {
                        latitude: gps.lat(),
                        longitude: gps.lng()
                    };
                    resolve(latLng);
                });
            });
        });
    }
}
