import '../../../../src/components/protons/icon/Icon';
import getAbsoluteHeight from '@naturehouse/nh-essentials/lib/calculators/height';
import './ShowMore.pcss';

export enum ShowMoreEvents {
    FINISHED_CLOSING = 'finished_closing'
}

export enum ShowMoreType {
    TEXT = 'text',
    LIST = 'list'
}

export type ShowMoreTitles = {
    show_more: string;
    show_less: string;
};

export type ShowMoreProps = {
    id: string;
    type: ShowMoreType;
    title: ShowMoreTitles;
    lines?: number;
    content: HTMLElement | string;
    readonly?: boolean;
    open?: boolean;
};

export default class ShowMore extends HTMLElement {
    #animation: Animation | null = null;

    #toggleElement: HTMLButtonElement | null = this.querySelector('[data-role="toggle"]');

    #content: HTMLElement | null = this.querySelector('[data-role="content"]');

    #lines: number = Number(this.getAttribute('lines')) ?? 0;

    #type: string = this.getAttribute('type') ?? ShowMoreType.TEXT;

    #toggleTimeout: number | null = null;

    readonly #animationDuration = 200;

    get open(): boolean {
        return this.hasAttribute('open');
    }

    set open(value: boolean) {
        this.toggleAttribute('open', value);
        this.toggleAttribute('closed', !value);
    }

    get readOnly(): boolean {
        return this.hasAttribute('read-only');
    }

    set readOnly(value: boolean) {
        this.toggleAttribute('read-only', value);
    }

    get lines(): number {
        return this.#lines;
    }

    set lines(value: number) {
        this.#lines = value;
        this.setAttribute('lines', value.toString());
    }

    get content(): HTMLElement | null {
        return this.#content;
    }

    get closed(): boolean {
        return this.hasAttribute('closed');
    }

    protected connectedCallback(): void {
        if (!this.#toggleElement || this.content === null) {
            throw new Error('Toggle Element is not initialized correctly');
        }

        this.#initialize();
        this.content.style.webkitLineClamp = this.open ? 'unset' : this.lines.toString();
        this.#toggleElement.addEventListener('click', this.#handleToggle);
    }

    protected disconnectedCallback(): void {
        this.#toggleElement?.removeEventListener('click', this.#handleToggle);
    }

    #setDefaultOpenedState(): void {
        if (this.content === null) {
            return;
        }

        const styles = window.getComputedStyle(this.content);
        const lineHeight = Number(styles.lineHeight.substring(0, styles.lineHeight.length - 2));
        const maxHeight = lineHeight * this.lines;
        const contentHeight = this.content.offsetHeight;

        if (maxHeight >= contentHeight && contentHeight > 0) {
            this.open = true;
            this.readOnly = true;
        }
    }

    #initialize(): void {
        if (this.#type === ShowMoreType.LIST) {
            this.#setInitialListHeight();
            return;
        }

        this.#setDefaultOpenedState();
    }

    #setInitialListHeight(): void {
        if (this.content === null) {
            return;
        }

        const marginTop = this.#getListMarginTop();
        const listHeight = this.open
            ? this.#getFullListHeight()
            : this.#getListItemHeight(this.#lines);

        this.content.style.maxHeight = `${listHeight + marginTop}px`;
    }

    #handleToggle = (): void => {
        if (this.#toggleTimeout !== null) {
            return;
        }

        this.#toggleTimeout = window.setTimeout(() => {
            this.#toggleTimeout = null;
        }, this.#animationDuration);

        if (this.#animation !== null && this.#animation.playState === 'running') {
            this.#animation.cancel();
        }

        this.#toggle();
    };

    #toggle = (): void => {
        window.requestAnimationFrame(() => {
            if (this.open) {
                this.#close();
                return;
            }

            this.#open();
        });
    };

    #open(): void {
        if (this.#type === ShowMoreType.LIST) {
            this.#openList();
            return;
        }

        this.#openText();
    }

    #close(): void {
        if (this.#type === ShowMoreType.LIST) {
            this.#closeList();
            return;
        }

        this.#closeText();
    }

    #openText(): void {
        if (this.content === null) {
            return;
        }

        this.content.style.webkitLineClamp = 'unset';

        this.#addAnimation(true);
    }

    #closeText(): void {
        if (this.content === null) {
            return;
        }

        this.content.style.webkitLineClamp = this.lines.toString();
        this.#addAnimation(false);
        this.#animation?.finished.then(() =>
            this.dispatchEvent(new Event(ShowMoreEvents.FINISHED_CLOSING))
        );
    }

    #openList(): void {
        if (this.content === null) {
            return;
        }

        this.#setInitialListHeight();

        const marginTop = this.#getListMarginTop();
        const listHeight = this.#getFullListHeight();
        this.content.style.maxHeight = `${listHeight + marginTop}px`;

        this.open = true;
    }

    #closeList(): void {
        if (this.content === null) {
            return;
        }

        const marginTop = this.#getListMarginTop();
        const listHeight = this.#getListItemHeight(this.lines);
        this.content.style.maxHeight = `${listHeight + marginTop}px`;

        this.open = false;
    }

    #getListItemHeight(lines: number): number {
        const listItems = this.querySelectorAll('LI, .nh-show-more__list-item');

        if (listItems.length === 0 || this.content === null) {
            return 0;
        }

        return Array.from(listItems)
            .slice(0, lines)
            .map((listItem) => {
                const styles = window.getComputedStyle(listItem);
                const margin = parseFloat(styles.marginBlock);
                return listItem.clientHeight + margin;
            })
            .reduce((prev, value) => prev + value, 0);
    }

    #getFullListHeight(): number {
        const list = this.querySelector('ol, ul, .nh-show-more__list');

        if (list === null || this.content === null) {
            return 0;
        }

        return list.clientHeight;
    }

    #getListMarginTop(): number {
        const list = this.querySelector('ol, ul, .nh-show-more__list');

        if (list === null || this.content === null) {
            return 0;
        }

        const styles = window.getComputedStyle(list);
        return parseFloat(styles.marginTop);
    }

    #addAnimation(open: boolean): void {
        if (this.content === null) {
            return;
        }

        const start = open ? this.#getHeights().startHeight : this.#getHeights().endHeight;
        const end = open ? this.#getHeights().endHeight : this.#getHeights().startHeight;

        this.#animation =
            this.content.animate(
                {
                    height: [`${start}px`, `${end}px`]
                },
                {
                    duration: this.#animationDuration,
                    easing: 'ease-out'
                }
            ) ?? null;

        this.#animation.onfinish = () => this.#onAnimationFinish(open);
        this.#animation.oncancel = () => this.#onAnimationFinish(!open);
    }

    #onAnimationFinish(open: boolean): void {
        this.open = open;
        this.#animation = null;
    }

    #getHeights(): { startHeight: number; endHeight?: number } {
        if (this.#type === ShowMoreType.TEXT) {
            return {
                startHeight:
                    (this.offsetHeight - getAbsoluteHeight(this.content ?? this)) * this.#lines,
                endHeight: getAbsoluteHeight(this.content ?? this)
            };
        }

        const li = this.querySelector('li');
        if (!li) {
            throw new Error('ShowMore: List item not found');
        }

        return {
            startHeight: (this.offsetHeight - getAbsoluteHeight(li ?? this)) * this.#lines
        };
    }
}

if (!window.customElements.get('nh-show-more')) {
    window.customElements.define('nh-show-more', ShowMore);
}
